import { Icon, Stroke, Style } from "ol/style";
import { SUPPORTED_HIGHWAY_TYPES } from "./supportedHighwayTypes";
import Point from "ol/geom/Point";
import {
  getNodeStyleNormal,
  getNodeStyleClick,
  getNodeStyleHover,
} from "./node";
import {
  getPropertyEndingWith,
  getPropertyEndingWithExcludingLayer7,
} from "./propertyExtraction";

export function getOsmOnewayStyleNormal(feature) {
  return getOnewayColorStyle(feature)
    .concat(getArrowStyle(feature))
    .concat(getNodeStyleNormal(feature));
}
export function getOnewayStyleClick(feature) {
  return getOnewayColorStyle(feature, true, false)
    .concat(getArrowStyle(feature))
    .concat(getNodeStyleClick(feature));
}
export function getOnewayStyleHover(feature) {
  return getOnewayColorStyle(feature, false, true)
    .concat(getArrowStyle(feature))
    .concat(getNodeStyleHover(feature));
}
function getOnewayColorStyle(feature, click, hover) {
  let color;
  const osmOneway = getPropertyEndingWithExcludingLayer7(feature, "-oneway");
  const osmHighway = getPropertyEndingWith(feature, "-highway");
  const osmJunction = getPropertyEndingWith(feature, "-junction");
  if (osmHighway === undefined) {
    color = "black";
  } else if (!SUPPORTED_HIGHWAY_TYPES.includes(osmHighway)) {
    color = "grey";
  } else if (osmHighway === "motorway" && osmOneway === undefined) {
    color = "green";
  } else if (osmOneway === "no") {
    color = "blue";
  } else if (
    (osmJunction === "roundabout" || osmJunction === "circular") &&
    osmOneway === undefined
  ) {
    color = "green";
  } else if (osmOneway === "yes") {
    color = "green";
  } else if (osmOneway === "-1") {
    color = "cyan";
  } else {
    color = "blue";
  }
  if (click) {
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: 6,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "orange",
          width: 4,
        }),
      }),
    ];
  }
  if (hover) {
    return [
      new Style({
        stroke: new Stroke({
          color: "white",
          width: 8,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: color,
          width: 4,
        }),
      }),
    ];
  }
  return [
    new Style({
      stroke: new Stroke({
        color: color,
        width: 2,
      }),
    }),
  ];
}

export function getArrowStyle(feature) {
  const osmOneway = getPropertyEndingWithExcludingLayer7(feature, "-oneway");
  const styles = [];
  if (typeof feature.getGeometry().forEachSegment === "function") {
    feature.getGeometry().forEachSegment(function (start, end) {
      const mid = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      if (osmOneway === "yes") {
        styles.push(
          new Style({
            geometry: new Point(mid),
            image: new Icon({
              src: "icon-arrow.png",
              anchor: [0.75, 0.5],
              rotateWithView: true,
              rotation: -rotation,
              color: "green",
              scale: [0.5, 0.5],
            }),
          }),
        );
      }
      if (osmOneway === "-1") {
        styles.push(
          new Style({
            geometry: new Point(mid),
            image: new Icon({
              src: "icon-arrow.png",
              anchor: [0.75, 0.5],
              rotateWithView: true,
              rotation: -rotation + Math.PI,
              color: "green",
              scale: [0.5, 0.5],
            }),
          }),
        );
      }
    });
  }
  return styles;
}
