import { Stroke, Style } from "ol/style";
import { SUPPORTED_HIGHWAY_TYPES } from "./supportedHighwayTypes";
import {
  getNodeStyleNormal,
  getNodeStyleClick,
  getNodeStyleHover,
} from "./node";
import { getPropertyEndingWith } from "./propertyExtraction";

export function getDualCarriagewayStyleNormal(feature) {
  return getDualCarriagewayColorStyle(feature).concat(
    getNodeStyleNormal(feature),
  );
}
export function getDualCarriagewayStyleClick(feature) {
  return getDualCarriagewayColorStyle(feature, true, false).concat(
    getNodeStyleClick(feature),
  );
}
export function getDualCarriagewayStyleHover(feature) {
  return getDualCarriagewayColorStyle(feature, false, true).concat(
    getNodeStyleHover(feature, true, false),
  );
}
function getDualCarriagewayColorStyle(feature, click, hover) {
  let color, width;
  const orbisDualCarriageway = getPropertyEndingWith(
    feature,
    "-dual_carriageway",
  );
  const orbisDualCarriagewayIsLR =
    orbisDualCarriageway !== undefined && orbisDualCarriageway.includes("#");
  const osmHighway = getPropertyEndingWith(feature, "-highway");
  let lineDash = null;
  if (osmHighway === undefined) {
    color = "black";
  } else if (!SUPPORTED_HIGHWAY_TYPES.includes(osmHighway)) {
    color = "grey";
  } else if (orbisDualCarriageway === "yes") {
    color = "green";
  } else if (orbisDualCarriagewayIsLR) {
    color = "violet";
  } else {
    color = "lightgreen";
  }
  if (orbisDualCarriageway === "yes") {
    width = 3;
  } else if (orbisDualCarriagewayIsLR) {
    width = 2;
    lineDash = [1, 3];
  } else {
    width = 1;
  }
  if (click) {
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 3,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "orange",
          width: width * 2,
          lineDash: lineDash,
        }),
      }),
    ];
  }
  if (hover) {
    return [
      new Style({
        stroke: new Stroke({
          color: "white",
          width: width * 4,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 2,
          lineDash: lineDash,
        }),
      }),
    ];
  }
  return [
    new Style({
      stroke: new Stroke({
        color: color,
        width: width,
        lineDash: lineDash,
      }),
    }),
  ];
}
