import { Stroke, Style } from "ol/style";
import { getPropertyEndingWith } from "./propertyExtraction";

export function getHDLaneFunctionStyleNormal(feature) {
  return getHDLaneFunctionColorStyle(feature);
}
export function getHDLaneFunctionStyleClick(feature) {
  return getHDLaneFunctionColorStyle(feature, true, false);
}
export function getHDLaneFunctionStyleHover(feature) {
  return getHDLaneFunctionColorStyle(feature, false, true);
}
function getHDLaneFunctionColorStyle(feature, click, hover) {
  let color, width, backgroundColor, backgroundWidth;

  const laneFunction = getPropertyEndingWith(feature, "-lane_function");
  const vehicleRestriction = getPropertyEndingWith(
    feature,
    "-vehicle_restriction",
  );
  if (laneFunction === undefined && vehicleRestriction === undefined) {
    return [];
  }
  if (laneFunction.includes("drivable")) {
    color = "green";
  } else {
    color = "red";
  }
  if (vehicleRestriction.includes("bus")) {
    backgroundColor = "yellow";
  } else {
    backgroundColor = "grey";
  }
  width = 2;
  backgroundWidth = 6;
  if (hover || click) {
    width = 2;
    backgroundWidth = 8;
  }
  return [
    new Style({
      stroke: new Stroke({
        color: backgroundColor,
        width: backgroundWidth,
      }),
    }),
    new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    }),
  ];
}
